import { IconPlus } from "@tabler/icons-react";
import { ReactElement } from "react";
import { ToggleableProps } from "../../types/toggleable";

const Toggleable = ({
  id,
  label,
  icon,
  children,
  openToggleableId,
  fadeDelay,
  setWichIsOpen,
  fadeEffect = true,
  style,
}: ToggleableProps): ReactElement => {
  const handleToggle = (): void =>
    setWichIsOpen(openToggleableId === id ? "" : id);

  return (
    <div className={`toggleable ${openToggleableId === id && "opened"}`}>
      <div
        style={style}
        className="label"
        onClick={handleToggle}
        data-aos={fadeEffect ? "fade-up" : ""}
        data-aos-delay={fadeDelay}
      >
        {icon}
        <div className="text">{label}</div>
        <IconPlus className="icon rotate" size={30} />
      </div>
      <div
        className="content"
        style={style}
        data-aos={fadeEffect ? "fade-up" : ""}
        data-aos-delay={fadeDelay}
      >
        {children}
      </div>
    </div>
  );
};

export default Toggleable;
