export const formatDoorStatusCardClass = (status: string): string => {
  switch (status) {
    case "locked":
      return "ok";
    case "unlocked":
      return "ok";
    default:
      return "unknown";
  }
};
