import axios from "axios";
import { socraftApiBaseUrl } from "../../environnment";

export const makeGetRequest = async (
  path: string,
  authenticated: boolean,
  accessToken: string,
): Promise<any> => {
  const requestHeaders = {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: "",
  };

  if (authenticated) {
    requestHeaders.Authorization = `Bearer ${accessToken}`;
  }

  const result = await axios.get(`${socraftApiBaseUrl}${path}`, {
    headers: requestHeaders,
  });

  if (String(result.status).split("")[0] !== "2") {
    throw result.data.error;
  }

  return result.data;
};
