import Swal from "sweetalert2";

export const askForConfirmation = async (text: string): Promise<boolean> => {
  try {
    const result = await Swal.fire({
      icon: "question",
      title: "Êtes-vous sûr(e) ?",
      text,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonColor: "#282828",
      confirmButtonColor: "#fbbb12",
      cancelButtonText: "Annuler",
      confirmButtonText: "<span style='color: #282828;'>Confirmer</span>",
      customClass: "popup",
    });

    return result.isConfirmed;
  } catch (error: any) {
    throw new Error(error);
  }
};
