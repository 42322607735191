import {
  IconBuildingEstate,
  IconCalendar,
  IconChevronRight,
  IconHome,
  IconListDetails,
  IconNews,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import { ReactElement } from "react";

const TabIcon = ({ name }: any): ReactElement => {
  switch (name) {
    case "Accueil":
      return <IconHome size={30} />;
    case "Coworking":
      return <IconBuildingEstate size={30} />;
    case "Profil":
      return <IconUser size={30} />;
    case "Annuaire":
      return <IconListDetails size={30} />;
    case "Calendrier":
      return <IconCalendar size={30} />;
    case "Comptes":
      return <IconSettings size={30} />;
    case "Nouveautés":
      return <IconNews size={30} />;
    default:
      return <IconChevronRight size={30} />;
  }
};

export default TabIcon;
