import { IconCheck, IconFileInvoice, IconTrash } from "@tabler/icons-react";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHttp } from "../../providers/HttpProvider";
import { askForConfirmation } from "../../shared/popups/askForConfirmation";
import toast from "../../shared/popups/toast";
import { Profile } from "../../types/profile";
import CardLoading from "../common/CardLoading";

interface CvUploaderProps {
  downloadUrl: string | undefined;
  profile?: Profile;
  email: string;
  own?: boolean;
}

const CvUploader: FC<CvUploaderProps> = ({
  downloadUrl,
  profile,
  email,
  own,
}) => {
  const [cvDownloadUrl, setCvDownloadUrl] = useState<string | undefined>(
    undefined,
  );
  const [changesHaveBeenMade, setChangesHaveBeenMade] =
    useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const { put, del } = useHttp();
  const { handleSubmit } = useForm();

  useEffect(() => {
    setCvDownloadUrl(downloadUrl);
  }, [downloadUrl]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChangesHaveBeenMade(true);
    setFile((e.target.files ?? [])[0]);
  };

  const handleSaveChanges = async (): Promise<void> => {
    const actionConfirmed =
      !cvDownloadUrl ||
      (await askForConfirmation(
        "Vous avez déjà un CV sur my.socraft. Êtes-vous sûr(e) de vouloir le remplacer ?",
      ));

    if (file && actionConfirmed) {
      setLoading(true);
      const requestUrl = `/profiles/${own ? "own" : email}/cv`;
      const result = await put(requestUrl, file);
      setCvDownloadUrl(result.cvDownloadUrl);

      setChangesHaveBeenMade(false);
      setLoading(false);

      void toast("success", "CV Uploadé !");
    }

    if (!actionConfirmed) {
      setChangesHaveBeenMade(false);
      setFile(undefined);
    }

    setFile(undefined);
  };

  const handleDeleteCv = async (): Promise<void> => {
    const actionConfirmed = await askForConfirmation(
      "Êtes-vous sûr(e) de vouloir supprimer votre CV ? Cette action est irréversible.",
    );

    if (actionConfirmed) {
      setLoading(true);
      const requestUrl = `/profiles/${own ? "own" : email}/cv`;
      await del(requestUrl);

      setCvDownloadUrl(undefined);
      setLoading(false);
      void toast("success", "CV supprimé !");
    }
  };

  const handleCancelChanges = (): void => {
    setChangesHaveBeenMade(false);
    setFile(undefined);
  };

  const handleDragZoneHover = (e: any, dragging: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(dragging);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setChangesHaveBeenMade(true);

    const file = e.dataTransfer.files[0];
    file && setFile(file);
  };

  return (
    <form
      className="cv-uploader-container"
      onSubmit={handleSubmit(handleSaveChanges)}
    >
      <input onChange={(e) => handleFileChange(e)} type="file" id="file" />
      {cvDownloadUrl && (
        <div className="already-existing-advert">
          {own ? "Vous avez" : `${profile?.firstname} ${profile?.lastname} a`}{" "}
          déjà uploadé un CV sur my.socraft
          <a href={cvDownloadUrl} rel="noreferrer" target="_blank">
            Visualiser
          </a>
          <span
            style={{
              fontWeight: "600",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleDeleteCv}
            className="yellow"
          >
            Supprimer
          </span>
        </div>
      )}
      <label
        onDragEnter={(e) => handleDragZoneHover(e, true)}
        onDragLeave={(e) => handleDragZoneHover(e, false)}
        onDragOver={(e) => handleDragZoneHover(e, true)}
        onDrop={handleDrop}
        className={dragging ? "cv-uploader dragging" : "cv-uploader"}
        htmlFor={!loading ? "file" : ""}
      >
        {loading ? (
          <CardLoading />
        ) : (
          <>
            <IconFileInvoice size={140} />
            <h2>Glissez ou déposez un CV</h2>
            {file && <span>({file.name} sélectionné)</span>}
          </>
        )}
      </label>
      {changesHaveBeenMade && (
        <div className="actions floating">
          <button
            onClick={handleCancelChanges}
            className="secondary"
            type="reset"
          >
            Supprimer les modifications
            <IconTrash />
          </button>
          <button disabled={!file} className="primary" type="submit">
            Enregistrer le CV
            <IconCheck />
          </button>
        </div>
      )}
    </form>
  );
};

export default CvUploader;
