import { IconArrowLeft, IconZoomExclamation } from "@tabler/icons-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Date from "../components/common/Date";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
  const navigate = useNavigate();

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconZoomExclamation size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Erreur 404</h1>
          <Date />
        </div>
      </header>
      <BlockLayer>
        <h1 style={{ fontSize: "3em" }}>Oups !</h1>
        <h4 style={{ fontSize: "1.7em" }}>
          Il semblerait que la page que vous cherchez n&apos;existe pas
        </h4>
        <br />
        <button className="primary" onClick={() => navigate("/")}>
          <IconArrowLeft />
          Retour à l&apos;accueil
        </button>
      </BlockLayer>
    </PageLayer>
  );
};

export default NotFound;
