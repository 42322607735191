import axios from "axios";
import { socraftApiBaseUrl } from "../../environnment";

export const makePostRequest = async (
  path: string,
  body: any,
  accessToken: string,
): Promise<any> => {
  const result = await axios.post(`${socraftApiBaseUrl}${path}`, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (result.status.toString().split("")[0] !== "2") {
    throw result.data.error;
  }

  return result.data;
};
