import {
  IconCheck,
  IconClipboard,
  IconPlus,
  IconSettings,
} from "@tabler/icons-react";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "../../shared/popups/toast";
import { CrafterElementProps } from "../../types/directory";

const Crafter = ({ crafter, canEdit }: CrafterElementProps): ReactElement => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState<boolean>(false);

  const handleShowMore = (): string =>
    (window.location.href = `https://socraft.community/profile/${crafter.shortId}`);

  const handleEditCrafter = (): void => navigate(`/directory/${crafter.email}`);

  const handleCopyProfileId = async (): Promise<void> => {
    if (crafter.id) {
      await navigator.clipboard.writeText(crafter.id);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } else {
      toast("error", "Impossible de copier l'ID du crafter");
    }
  };

  return (
    <div className="crafter-card">
      {crafter.openToWork && (
        <div className="open-to-work-badge">
          Open To Work <IconCheck />
        </div>
      )}
      <div className="header">
        <img
          className="avatar"
          alt="Crafter avatar"
          loading="lazy"
          src={crafter.profilePicture}
        />
        <div className="infos">
          <h2 className="fullname">
            {crafter.firstname}{" "}
            <span className="yellow">{crafter.lastname}</span>
          </h2>
          <div className="contact">
            {crafter.email?.length && (
              <span className="info">{crafter.email}</span>
            )}
            {crafter.phone?.length && (
              <span className="info">{crafter.phone}</span>
            )}
          </div>
        </div>
      </div>
      <div className="actions">
        {canEdit && (
          <button onClick={handleEditCrafter} className="secondary">
            Modifier
            <IconSettings />
          </button>
        )}
        <button onClick={handleShowMore} className="primary">
          Voir plus
          <IconPlus />
        </button>
        <div className="profile-id">
          {canEdit && (
            <span
              className="info"
              onClick={handleCopyProfileId}
              style={{ cursor: "pointer", position: "relative" }}
              data-info="Copier l'ID du crafter"
            >
              <IconClipboard />
              {crafter.id?.slice(0, 20)}...
              <span
                className="tooltip"
                style={{ position: "absolute", top: "100%", left: 0 }}
              >
                {copied
                  ? "Copié dans presse-papiers!"
                  : "Copier l'ID du crafter"}
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Crafter;
