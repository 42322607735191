import { ProfileInput } from "../../types/profile";

export const otherInputs: ProfileInput[] = [
  {
    property: "entryDate",
    type: "date",
    placeholder: "Date d'adhésion",
    isTextarea: false,
  },
  {
    type: "text",
    placeholder: "Testimonial",
    property: "testimonial",
    isTextarea: true,
  },
];
