import { ReactElement } from "react";
import Spinner from "./Spinner";

const Loader = (): ReactElement => {
  return (
    <div className="loader-container">
      <Spinner />
    </div>
  );
};

export default Loader;
