import { ReactElement } from "react";
import { LayerProps } from "../types/layer";

const AuthLayer = ({ children }: LayerProps): ReactElement => {
  return (
    <div className="container">
      <section className="authentication">{children}</section>
    </div>
  );
};

export default AuthLayer;
