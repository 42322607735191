import axios from "axios";
import { socraftApiBaseUrl } from "../../environnment";

export const makeDeleteRequest = async (
  path: string,
  accessToken: string,
  data?: any,
): Promise<any> => {
  const result = await axios.delete(`${socraftApiBaseUrl}${path}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });

  if (result.status !== 200) {
    throw result.data.error;
  }

  return result.data;
};
