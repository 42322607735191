import { useAuth0 } from "@auth0/auth0-react";
import { IconLogin2, IconPlus } from "@tabler/icons-react";
import { ReactElement } from "react";
import crafter from "../images/crafter.png";
import logo from "../images/logo-dark.png";
import AuthLayer from "../layers/AuthLayer";

const Authenticate = (): ReactElement => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async (): Promise<void> => {
    try {
      await loginWithRedirect();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <AuthLayer>
      <img className="logo large" alt="my.socraft Logo" src={logo} />
      <div className="authentication-content">
        <h3 className="auth-title">Connectez-vous à la communauté !</h3>
        <button onClick={handleLogin} className="primary">
          Se connecter
          <IconLogin2 />
        </button>
      </div>
      <div className="join-community">
        <div className="text">
          <h2>Devenez</h2>
          <img alt="crafter" src={crafter} height={30} />
        </div>
        <button
          onClick={() => window.open("https://join.socraft.ch")}
          className="secondary"
        >
          En savoir plus
          <IconPlus />
        </button>
      </div>
    </AuthLayer>
  );
};

export default Authenticate;
