import { Birthday } from "../../types/profile";

export const formatToBirthday = (date: any | string): Birthday => {
  date = new Date(date);

  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());

  return {
    month: (Number(month) < 10 ? "0" : "") + month,
    day: (Number(day) < 10 ? "0" : "") + day,
  };
};
