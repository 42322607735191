export interface CheckCrafterAccountValuesResult {
  isValid: boolean;
  message?: string;
}

export const checkCrafterAccountValues = (
  firstname: string,
  lastname: string,
  email: string,
  shouldCreateAGoogleWorkspaceAccount: boolean,
  googleWorkspaceAccountEmail?: string,
): CheckCrafterAccountValuesResult => {
  if (!firstname || !lastname || !email) {
    return {
      isValid: false,
      message: "Vous devez renseigner un nom, un prénom et une adresse E-Mail",
    };
  }

  if (!checkEmailFormat(email)) {
    return {
      isValid: false,
      message: "Vous devez donner une adresse E-Mail valide",
    };
  }

  if (
    shouldCreateAGoogleWorkspaceAccount &&
    (!googleWorkspaceAccountEmail ||
      !checkEmailFormat(googleWorkspaceAccountEmail))
  ) {
    return {
      isValid: false,
      message:
        "Vous devez donner une adresse E-Mail valide pour le compte Google Workspace",
    };
  }

  return {
    isValid: true,
    message: undefined,
  };
};

const checkEmailFormat = (email: string): boolean => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ) as unknown as boolean;
};
