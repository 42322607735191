export const formatDoorStatus = (status: string): string => {
  switch (status) {
    case "locked":
      return "verrouillé";
    case "unlocked":
      return "déverrouillé";
    default:
      return "statut inconnu";
  }
};
