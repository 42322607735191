import { ReactElement } from "react";
import { LayerProps } from "../types/layer";

const PageLayer = ({ children }: LayerProps): ReactElement => {
  return (
    <div className="container">
      <section className="page">{children}</section>
    </div>
  );
};

export default PageLayer;
