import axios from "axios";
import { socraftApiBaseUrl } from "../../environnment";

export const makePutRequest = async (
  path: string,
  file: File,
  accessToken: string,
): Promise<any> => {
  const requestUrl = `${socraftApiBaseUrl}${path}`;

  const formData = new FormData();
  formData.append("file", file);

  const result = await axios({
    method: "put",
    url: requestUrl,
    data: formData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  });

  if (result.status.toString().split("")[0] !== "2") {
    throw result.data.error;
  }

  return result.data;
};
