import { ReactElement } from "react";
import { CardProps } from "../../types/card";

const Card = ({
  hasHeader,
  headerTitle,
  children,
  hasFooter,
  footerContent,
  customClass,
  aosDelay,
  smoothAppear,
}: CardProps): ReactElement => {
  return (
    <div
      data-aos={
        (smoothAppear === true || smoothAppear === undefined) && "fade-up"
      }
      data-aos-delay={aosDelay}
      className={`card ${customClass}`}
    >
      {hasHeader && <header>{headerTitle}</header>}
      <div className="card-body">{children}</div>
      {hasFooter && <footer>{footerContent}</footer>}
    </div>
  );
};

export default Card;
