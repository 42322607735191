import dayjs, { Dayjs } from "dayjs";
import { getRawDate } from "./formatDate";

export const differenceFromNowInDays = (timestamp: number): string => {
  let prefix: "dans" | "il y a" = "dans";

  const date: string = getRawDate(new Date(timestamp).getTime());
  const now: Dayjs = dayjs(getRawDate(new Date().getTime()));

  const differenceInDays = now.diff(date, "days") * -1;

  if (differenceInDays === 0) {
    return "aujourd'hui";
  }

  if (differenceInDays < 0) {
    prefix = "il y a";
  }

  return `${prefix} ${Math.abs(differenceInDays)} jour${Math.abs(differenceInDays) > 1 ? "s" : ""}`;
};
