import { ProfileInput } from "../../types/profile";

export const informationsInputs: ProfileInput[] = [
  {
    type: "text",
    placeholder: "Prénom",
    property: "firstname",
  },
  {
    type: "text",
    placeholder: "Nom",
    property: "lastname",
  },
  {
    type: "email",
    placeholder: "Adresse Email",
    property: "email",
    readOnly: true,
  },
  {
    placeholder: "Ma bio",
    property: "bio",
    isTextarea: true,
  },
  {
    type: "date",
    placeholder: "Date de naissance",
    property: "birth_date",
  },
  {
    type: "tel",
    placeholder: "Numéro de téléphone",
    property: "phone",
  },
  {
    type: "url",
    placeholder: "Profil linkedin",
    property: "linkedin",
  },
];
