const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export const formatDate = (timestamp: number): string => {
  const date: any = new Date(timestamp);

  if (!isNaN(date)) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day} ${months[Number(Number(month) - 1)]} ${year}`;
  } else {
    return "";
  }
};

export const getRawDate = (timestamp: number): string => {
  const date: any = new Date(timestamp);

  if (!isNaN(date)) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
};

export const toTimestamp = (date: string): number => {
  let timestamp: any = "";
  if (date !== undefined) {
    const explodedDate = date.split("-");

    const dateYear = Number(explodedDate[0]);
    const dateMonth = Number(explodedDate[1]) - 1;
    const dateDay = Number(explodedDate[2]);

    timestamp = new Date(dateYear, dateMonth, dateDay, 1, 0, 0).getTime();
  }

  return timestamp;
};
