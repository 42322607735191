import { IconActivity, IconNews } from "@tabler/icons-react";
import { useState } from "react";
import Date from "../components/common/Date";
import Toggleable from "../components/common/Toggleable";
import BlockLayer from "../layers/BlockLayer";
import PageLayer from "../layers/PageLayer";

const ReleaseNotes = () => {
  const [openToggleable, setOpenToggleable] = useState<string>("june");

  return (
    <PageLayer>
      <header>
        <div className="icon">
          <IconNews size={55} />
        </div>
        <div className="text">
          <h1 className="page-title">Nouveautés</h1>
          <Date />
        </div>
      </header>
      <BlockLayer>
        <Toggleable
          id="june"
          setWichIsOpen={setOpenToggleable}
          openToggleableId={openToggleable ?? "open"}
          label="Juin 2024"
          icon={<IconActivity />}
          fadeDelay={100}
        >
          <ul>
            <h3>- 20.06.2024</h3>
            <li>
              OpenToWork : Un badge est affiché sur les profils des crafters qui
              ont coché <a href={"profile"}>l&apos;option OpenToWork</a>
            </li>
          </ul>
          <ul>
            <h3>- 18.06.2024</h3>
            <li>
              Amélioration du responsive : Certaines modifications ont été
              apportées afin d&apos;améliorer l&apos;expérience utilisateur sur
              mobile.
            </li>
            <li>
              Page de contacts socraft : Un nouvel onglet dédié aux contacts sur
              socraft est maintenant disponible en cliquant sur le bouton de
              support, facilitant l&apos;interaction et la communication.
            </li>
          </ul>
          <ul>
            <h3>- 17.06.2024</h3>
            <li>
              Lien public pour chaque profil de crafter : Vous pouvez dès à
              présent partager <a href={"profile"}>votre profil</a> en copiant
              le lien
            </li>
            <li>
              Adresse mail lors de la création de compte : premium@socraft.ch à
              support@socraft.ch
            </li>
            <li>
              Nom de domaine crafters.socraft.ch : La visualisation de la
              communauté et de tous les crafters se trouve maintenant sur{" "}
              <a href="https://crafters.socraft.ch">crafters.socraft.ch</a>
            </li>
          </ul>
          <ul>
            <h3>- 14.06.2024</h3>
            <li>
              Minibio des formateurs : Sur la page de{" "}
              <a href="https://training.socraft.ch">détail des formations</a>,
              il n&apos;y a plus la bio des formateurs
            </li>
            <li>
              Adresse postale dans le profil : L&apos;adresse est maintenant
              divisée en <a href={"profile"}>plusieurs champs</a>
            </li>
          </ul>
          <ul>
            <h3>- 11.06.2024</h3>
            <li>
              Bouton de support : Un bouton facilitant la demande d&apos;aide a
              ajouté en bas à droite sur my.socraft.ch
            </li>
            <li>
              Lien de partage sur les formations : Vous pouvez maintenant
              partager le lien d&apos;une formation sur{" "}
              <a href="https://training.socraft.ch">training.socraft.ch</a>{" "}
              facilitant l&apos;échange
            </li>
          </ul>
          <ul>
            <h3>- 10.06.2024</h3>
            <li>
              Visualisation de toute la communauté : Regroupement de tous les
              crafters avec leur photo de profil
            </li>
            <li>
              Détails des formations : Une page est maintenant dédiées avec la
              description, le formateurs et toutes les informations sur{" "}
              <a href="https://training.socraft.ch">training.socraft.ch</a>
            </li>
            <li>
              Le lieu et l&apos;accès au site dans la même carte : Vous pouvez
              l&apos;adresse ainsi que les informations d&apos;accès sur la{" "}
              <a href={"coworking"}>même carte</a>
            </li>
          </ul>
          <ul>
            <h3>- 07.06.2024</h3>
            <li>
              Le QG renommé en <a href={"coworking"}>Coworking</a>
            </li>
          </ul>
        </Toggleable>
      </BlockLayer>
    </PageLayer>
  );
};

export default ReleaseNotes;
