import { createContext, FC, useContext, useEffect, useState } from "react";
import toast from "../shared/popups/toast";
import { Event } from "../types/event";
import { useHttp } from "./HttpProvider";

interface EventsContextProps {
  events: Event[];
}
interface EventsProviderProps {
  children: React.ReactNode;
}

const EventsContext = createContext<EventsContextProps | undefined>(undefined);

const EventsProvider: FC<EventsProviderProps> = ({ children }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const { get } = useHttp();

  useEffect(() => {
    const initEvents = async (): Promise<void> => {
      try {
        const result = await get("/public/events");
        setEvents(result.events);
      } catch (error: any) {
        void toast(
          "error",
          `Oups ! Une erreur est survenue lors de la récuperation des évènements : ${error.response.data.error}`,
        );
      }
    };

    void initEvents();
  }, [get]);

  return (
    <EventsContext.Provider
      value={{
        events,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export default EventsProvider;

export const useEvents = (): EventsContextProps => {
  const eventsContext = useContext<EventsContextProps | undefined>(
    EventsContext,
  );

  if (!eventsContext) {
    throw new Error("You need to wrap your component in an EventsContext");
  }

  return eventsContext;
};
