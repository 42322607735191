import { FC, useState } from "react";

interface TabsProps {
  options: OptionContent[];
}

export interface OptionContent {
  content: React.ReactNode;
  name: string;
  nameIcon?: React.ReactNode;
}

const Tabs: FC<TabsProps> = ({ options }) => {
  const [activeOption, setActiveOption] = useState<string>(options[0].name);

  const handleSelectOption = (option: OptionContent): void =>
    setActiveOption(option.name);

  return (
    <div className="tabs">
      <div className="options">
        {options.map((option, key) => (
          <button
            className={`option-button ${activeOption === option.name && "open"}`}
            key={key}
            onClick={() => handleSelectOption(option)}
          >
            {option.name}
            {option.nameIcon ?? ""}
          </button>
        ))}
      </div>
      <div className="content">
        {options.map((option, key) => (
          <div
            className={`option-content ${activeOption === option.name && "open"}`}
            key={key}
          >
            {option.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
