import {
  IconAddressBook,
  IconHeadset,
  IconMessage,
  IconSend,
  IconWriting,
  IconX,
} from "@tabler/icons-react";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHttp } from "../../providers/HttpProvider";
import toast from "../../shared/popups/toast";
import Card from "./Card";

interface DotsMenuProps {}

const DotsMenu: FC<DotsMenuProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { register, handleSubmit } = useForm();
  const { post } = useHttp();

  const handleSendForm = async ({
    textareaValue,
  }: {
    textareaValue: string;
  }): Promise<void> => {
    await post("/support/send", { description: textareaValue });
    toast(
      "success",
      "Votre demande d'aide a bien été envoyée. Vous allez recevoir une réponse dans les plus brefs délais !",
    );
  };

  const handleSupport = (): void => {
    withReactContent(Swal).fire({
      html: (
        <div className="popup-content">
          <form onSubmit={handleSubmit(handleSendForm)}>
            <h1>Contactez le support</h1>
            <div
              style={{ alignItems: "first baseline" }}
              className="input-group"
            >
              <IconWriting />
              <textarea
                style={{ minHeight: "250px", maxHeight: "400px" }}
                name="message"
                placeholder="Décrivez votre problème"
                {...register("textareaValue", { required: true })}
              ></textarea>
            </div>
            <br />
            <div className="actions">
              <button type="submit" className="primary">
                Envoyer
                <IconSend />
              </button>
            </div>
          </form>
        </div>
      ),
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      customClass: "popup",
    });
  };

  const handleContacts = (): void => {
    withReactContent(Swal).fire({
      html: (
        <div className="popup-content">
          <h1>Contacts</h1>
          <div className="card-group">
            <Card customClass="card-contact" smoothAppear={false}>
              <h2>Service admin</h2>
              <a
                href="mailto:admin@socraft.ch"
                target="_blank"
                rel="noreferrer"
                className="yellow bold pointer"
              >
                admin@socraft.ch
              </a>
              <p style={{ opacity: "0.3", fontWeight: "600" }}>
                Pour tous les contrats et l’administratif
              </p>
            </Card>
            <Card customClass="card-contact" smoothAppear={false}>
              <h2>Support</h2>
              <a
                href="mailto:support@socraft.ch"
                target="_blank"
                rel="noreferrer"
                className="yellow bold pointer"
              >
                support@socraft.ch
              </a>
              <p style={{ opacity: "0.3", fontWeight: "600" }}>
                Pour le support et autres questions
              </p>
            </Card>
            <Card customClass="card-contact" smoothAppear={false}>
              <h2>Service de facturation</h2>
              <a
                href="mailto:billing@socraft.ch"
                target="_blank"
                rel="noreferrer"
                className="yellow bold pointer"
              >
                billing@socraft.ch
              </a>
              <p style={{ opacity: "0.3", fontWeight: "600" }}>
                Pour facturer vos prestations
              </p>
            </Card>
          </div>
        </div>
      ),
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      customClass: "popup",
    });
  };

  return (
    <div className="dots">
      <button onClick={() => setIsOpen(!isOpen)} className="primary dots-menu">
        {isOpen ? <IconX /> : <IconHeadset />}
      </button>
      {isOpen && (
        <div className="options">
          <button
            data-aos="fade"
            className="dot-option"
            onClick={handleContacts}
          >
            <IconAddressBook size={27} />
          </button>
          <button
            data-aos="fade"
            className="dot-option"
            onClick={handleSupport}
          >
            <IconMessage size={27} />
          </button>
        </div>
      )}
    </div>
  );
};

export default DotsMenu;
