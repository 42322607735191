import { Profile } from "../../types/profile";

export function updateProfileObjectProperty<T extends keyof Profile>(
  prop: T,
  value: Profile[T],
  object: Profile,
): Profile {
  object[prop] = value;
  return object;
}
