import { Auth0Provider } from "@auth0/auth0-react";
import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { DirectoryProvider } from "./providers/DirectoryProvider";
import { DoorsProvider } from "./providers/DoorsProvider";
import EventsProvider from "./providers/EventsProvider";
import HttpProvider from "./providers/HttpProvider";
import { ProfileProvider } from "./providers/ProfileProvider";
import "./styles/index.css";

posthog.init("phc_BJg7ERvmod7hJSjby55IeT2yrwyWOetVQfOj10SYgdq", {
  api_host: "https://eu.posthog.com",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      cacheLocation={"localstorage"}
      domain="socraft.eu.auth0.com"
      clientId="jn1GJ7slnF1KgpkBNObnrdL7pV97hiQG"
      authorizationParams={{
        audience: "https://my.socraft.ch/api",
        redirect_uri: window.location.origin,
      }}
    >
      <BrowserRouter>
        <HttpProvider>
          <ProfileProvider>
            <DoorsProvider>
              <DirectoryProvider>
                <EventsProvider>
                  <App />
                </EventsProvider>
              </DirectoryProvider>
            </DoorsProvider>
          </ProfileProvider>
        </HttpProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
);
