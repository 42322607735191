import { displayedTabs } from "../../environnment";
import { Tab } from "../../types/tab";

export const filterAllowedTabs = (
  roles: string[],
  tabs: Tab[] = displayedTabs,
): Tab[] => {
  const tabsWithoutNeededSpecificRole = tabs.filter(
    (tab) => !tab.neededRoles?.length,
  );
  if (typeof roles !== "string") {
    const allowedTabs = tabs.filter((tab) =>
      tab.neededRoles?.some((neededRole) =>
        roles.some((role) => role === neededRole),
      ),
    );

    return [...tabsWithoutNeededSpecificRole, ...allowedTabs];
  }

  return tabsWithoutNeededSpecificRole;
};
