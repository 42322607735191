import { ReactElement, useEffect, useState } from "react";
import { isDev } from "../../environnment";

const EnvironmentBadge = (): ReactElement => {
  const [environmentType, setEnvironmentType] = useState<"dev" | "qa">();

  useEffect(() => {
    setEnvironmentType(isDev ? "dev" : "qa");
  }, []);

  return <span className="environment-badge">{`<${environmentType} />`}</span>;
};

export default EnvironmentBadge;
