import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

export const initAccessToken = async (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions,
  ) => Promise<string>,
  setAccessToken: (accessToken: string) => void,
  setLoading: (loading: boolean) => void,
): Promise<void> => {
  const accessToken = await getAccessTokenSilently();
  setAccessToken(accessToken);
  setLoading(false);
};
