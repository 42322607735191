import { useAuth0, User } from "@auth0/auth0-react";
import { Timestamp } from "@firebase/firestore";
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { isProd } from "../environnment";
import toast from "../shared/popups/toast";
import {
  Profile,
  ProfileContextProps,
  ProfileProviderProps,
} from "../types/profile";
import { useHttp } from "./HttpProvider";

export const ProfileContext = createContext(undefined);

export const ProfileProvider = ({
  children,
}: ProfileProviderProps): ReactElement => {
  const [cvFilePath, setCvFilePath] = useState<string | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(isProd);
  const [roles, setRoles] = useState<string[]>([]);
  const { loading, get, post } = useHttp();
  const { user } = useAuth0();

  const authEmail: string | null = user?.email ?? null;

  useEffect(() => {
    const initProfile = async (): Promise<void> => {
      try {
        setRoles(extractRolesFromUser(user));
        const getProfileResult = await get("/profiles/own");

        setProfile(getProfileResult.profile);

        if (!isProd) {
          setTermsAccepted(getProfileResult.profile.termsAccepted ?? false);
        }

        const getCvDownloadUrlResult = await get("/profiles/own/cv");
        setCvFilePath(getCvDownloadUrlResult.cvDownloadUrl);
      } catch (error: any) {
        if (error?.error !== "login_required") {
          void toast("error", `Oups ! Une erreur est survenue : ${error}`);
        }
      }
    };

    !loading && authEmail && void initProfile();
  }, [authEmail, user, loading, get]);

  const editProfile = async (updatedProfile: Profile): Promise<void> => {
    try {
      await post("/profiles/own/update", updatedProfile);

      setProfile(updatedProfile);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const acceptTerms = async (): Promise<void> => {
    try {
      const updatedProfile = {
        ...profile,
        termsAccepted: true,
        termsAcceptedAt: Timestamp.fromDate(new Date()),
      };

      await editProfile(updatedProfile as Profile);
      setTermsAccepted(true);
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const profileContextValue: any = {
    profile,
    termsAccepted,
    acceptTerms,
    roles,
    cvFilePath,
    setProfile,
    editProfile,
  };

  return (
    <ProfileContext.Provider value={profileContextValue}>
      {children}
    </ProfileContext.Provider>
  );
};

export const extractRolesFromUser = (user: User | undefined): string[] => {
  const roleKey = "https://socraft.ch/roles";
  return user?.[roleKey] || [];
};

export const useProfile = (): ProfileContextProps => {
  const profileContext = useContext<any>(ProfileContext);

  if (!profileContext) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }

  return profileContext;
};
