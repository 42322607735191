import { useAuth0 } from "@auth0/auth0-react";
import { IconCheck, IconExternalLink } from "@tabler/icons-react";
import aos from "aos";
import "aos/dist/aos.css";
import { ReactElement, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DotsMenu from "./components/common/DotsMenu";
import Loader from "./components/common/Loader";
import Sidebar from "./components/common/Sidebar";
import Accounts from "./pages/Accounts";
import Authenticate from "./pages/Authenticate";
import Coworking from "./pages/Coworking";
import Directory from "./pages/Directory";
import DirectoryCrafter from "./pages/DirectoryCrafter";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Profile from "./pages/Profile";
import ProfilePictureUploader from "./pages/ProfilePictureUploader";
import ReleaseNotes from "./pages/ReleaseNotes";
import { useDirectory } from "./providers/DirectoryProvider";
import { useProfile } from "./providers/ProfileProvider";
import { wishBirthdays } from "./shared/directory/wishBirthdays";

const App = (): ReactElement => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { birthdays } = useDirectory();
  const { profile, termsAccepted, acceptTerms } = useProfile();

  useEffect(() => {
    wishBirthdays(birthdays, profile);
  }, [birthdays, profile]);

  useEffect(() => {
    if (profile && !termsAccepted) {
      withReactContent(Swal).fire({
        customClass: "popup",
        showConfirmButton: false,
        html: (
          <div className="popup-content">
            <h2>Mise à jour de nos conditions d&apos;utilisation</h2>
            <p>
              Nous avons mis à jour nos conditions d&apos;utilisation et notre
              politique de confidentialité. Veuillez prendre connaissance de ces
              dernières et les accepter pour continuer à utiliser les services
              de socraft.
            </p>
            <div className="popup-actions">
              <button
                className="secondary"
                onClick={() => window.open("https://socraft.community/terms")}
              >
                Lire les conditions d&apos;utilisation
                <IconExternalLink />
              </button>
              <button
                className="primary"
                onClick={async () => {
                  await acceptTerms();
                  Swal.close();
                }}
              >
                J&apos;ai lu et j&apos;accepte les conditions d&apos;utilisation
                <IconCheck />
              </button>
            </div>
          </div>
        ),
      });
    }
  }, [profile, termsAccepted]);

  useEffect(() => {
    aos.init({
      once: true,
    });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Authenticate />;
  }

  return (
    <>
      <Sidebar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/coworking" element={<Coworking />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/profile/profilePicture"
          element={<ProfilePictureUploader />}
        />
        <Route path="/directory" element={<Directory />} />
        <Route path="/directory/:email" element={<DirectoryCrafter />} />
        <Route
          path="/directory/:email/profilePicture"
          element={<ProfilePictureUploader />}
        />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <DotsMenu />
    </>
  );
};

export default App;
